<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="3">
          <v-switch
            v-if="isSettingsPage"
            v-model="newNotification.is_active"
            :label="newNotification.is_active ? 'Включено' : 'Виключено'"
            color="success"
            dense
            hide-details
          ></v-switch>
        </v-col>
      </v-row>
      <!--      <SetFormLanguage @changeFormLanguage="onChangeFormLanguage" @deleteLanguage="onDeleteLanguage" :isEditable="isEditable" />-->
      <v-row>
        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <div class="mr-3">Назва:</div>
            <v-text-field v-model="newNotification.content[contentIndex].title" :rules="[rules.required]"></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <div class="text-h6 mb-5">Подія</div>
          <template v-if="isSettingsPage">
            <v-datetime-picker
              v-model="convertedStartAtDate"
              :textFieldProps="textFieldProps"
              :datePickerProps="datePickerProps"
              :timePickerProps="timePickerProps"
              :key="newNotification.start_at ? `start-${Date.now()}` : null"
              timeFormat="HH:mm:ss"
              dateFormat="dd-MM-yyyy"
              clear-text="очистити"
              ok-text="так"
              label="Термін дії з"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
            <v-datetime-picker
              v-model="convertedExpiredAtDate"
              :textFieldProps="textFieldProps"
              :datePickerProps="datePickerProps"
              :timePickerProps="timePickerProps"
              :key="newNotification.expired_at ? `expired-${Date.now()}` : null"
              timeFormat="HH:mm:ss"
              dateFormat="dd-MM-yyyy"
              clear-text="очистити"
              ok-text="так"
              label="Термін дії до"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
            <HelpTooltip
              right
              max-width="500"
              text="Ця подія для створення автоматичного повідомлення користувачу, який підпадає під обрану подію"
            >
              <v-select
                v-model="newNotification.event"
                :items="pushNotificationEventsDictionary"
                :rules="[rules.required]"
                @change="setPushVariables"
                item-text="name"
                item-value="value"
                label="Подія"
                dense
                outlined
              ></v-select>
            </HelpTooltip>
          </template>
          <HelpTooltip right>
            <v-select
              v-model="newNotification.type"
              :items="pushNotificationTypesDictionary"
              :rules="[rules.required]"
              :disabled="!newNotification.event && isSettingsPage"
              item-text="name"
              item-value="value"
              label="Тип пуш-повідомлення"
              dense
              outlined
            ></v-select>
            <template v-slot:htmlContent>
              <div>1. <span class="font-weight-bold">Інформаційне</span> - інформування клієнта щодо загальних подій</div>
              <div>2. <span class="font-weight-bold">Технічне</span> - пов’язано з технічними проблемами</div>
              <div>
                3. <span class="font-weight-bold">Важливе</span> - термінова, пріоритетна інформація (наприклад, “Торгова точка
                завтра не працює”)
              </div>
            </template>
          </HelpTooltip>
          <HelpTooltip right max-width="500" text="У клієнта в історії додатку Mate зберегти повідомлення  для перегляду">
            <v-select
              v-model="newNotification.is_save"
              :items="booleanOptions"
              :rules="[rules.required]"
              :disabled="!newNotification.type"
              item-text="text"
              item-value="value"
              label="Зберегти повідомлення клієнту"
              dense
              outlined
            ></v-select>
          </HelpTooltip>
          <v-select
            v-model="newNotification.receiver_type"
            :items="pushNotificationRecipientsDictionary"
            :rules="[rules.required]"
            :disabled="
              newNotification.is_save === null ||
              !!newNotification.receivers.length ||
              isReceiverTypeAllUsers(newNotification.event)
            "
            item-text="name"
            item-value="value"
            label="Групи отримувачів"
            dense
            outlined
            clearable
          ></v-select>
          <PagingAutocomplete
            :value="newNotification.receivers"
            :options="users"
            :options-meta="usersMeta"
            :options-params="usersOptions"
            :rules="newNotification.receiver_type === 4 ? [rules.required] : []"
            :disabled="newNotification.receiver_type !== 4"
            @change="(val) => (newNotification.receivers = val)"
            label="Користувачі"
            placeholder="Почніть вводити текст для пошуку"
            no-filter
            item-text="phone"
            item-value="id"
            clearable
            multiple
            dense
            outlined
          >
            <template v-slot:selection="{ item }"> {{ item.first_name }} {{ item.last_name }} ({{ item.phone }}) </template>
            <template v-slot:item="{ item }"> {{ item.first_name }} {{ item.last_name }} ({{ item.phone }}) </template>
          </PagingAutocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4" offset-md="2">
          <div class="text-h6 mb-5">Місце</div>
          <PushNotificationFilters :filter-defs="filterDefs" :is-visible="showPlaceFilter" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea
            v-model="newNotification.content[contentIndex].body"
            ref="textarea"
            :rules="[rules.required]"
            outlined
            label="Текст повідомлення"
          ></v-textarea>
          <!--          <vue-editor v-model="newNotification.content[contentIndex].body" ref="textarea" placeholder="Текст повідомлення" />-->
        </v-col>
        <v-col cols="12" sm="6">
          <div v-if="newNotification.event">
            <div class="mb-2">Доступні змінні для відображення в тексті пуш повідомлень:</div>
            <v-chip
              v-for="(variable, index) in pushVariables"
              :key="index"
              @click="insertText(variable)"
              class="mr-1 mb-1"
              color="primary"
              outlined
              small
            >
              {{ variable }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <v-btn @click="openPreviewModal" class="mr-2" color="primary" small outlined>попередній перегляд</v-btn>
        <v-btn @click="openModal" color="primary" small>
          {{ isSettingsPage ? 'Зберегти' : 'Відправити' }}
        </v-btn>
      </div>
      <PreviewMobileDialog
        @closeModal="showPreviewModal = false"
        :showModal="showPreviewModal"
        :notification="newNotification"
        :contentIndex="contentIndex"
      />
      <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
        <template v-slot:title>
          {{ isSettingsPage ? 'Зберегти?' : 'Ви дійсно хочете відправити?' }}
        </template>
        <template v-if="isSettingsPage" v-slot:text>Правило буде сформовано</template>
        <template v-slot:buttons>
          <v-btn @click="submit" color="primary" text>Так</v-btn>
          <v-btn @click="showModal = false" text>Нi</v-btn>
        </template>
      </ConfirmActionModal>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { PUSH_NOTIFICATION_SCHEMA } from '@/const/apiSchemas'
import CloneDeep from 'lodash/cloneDeep'
import ManageFiltersOptions from '@/mixins/manageFiltersOptions'
// import { VueEditor } from 'vue2-editor'

export default {
  name: 'NotificationForm',

  components: {
    // VueEditor,
    PagingAutocomplete: () => import('@/components/common/PagingAutocomplete'),
    // SetFormLanguage: () => import('@/components/common/forms/SetFormLanguage'),
    PushNotificationFilters: () => import('@/components/common/filters/PushNotificationFilters'),
    PreviewMobileDialog: () => import('@/components/dialogs/PreviewMobileDialog'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
    HelpTooltip: () => import('@/components/common/HelpTooltip'),
  },

  mixins: [ManageFiltersOptions],

  props: {
    isEditable: {
      required: false,
      type: Boolean,
      default: false,
    },
    currentNotificationId: {
      required: false,
      default: null,
    },
    isOneTime: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    PUSH_NOTIFICATION_SCHEMA: PUSH_NOTIFICATION_SCHEMA,
    newNotification: {},
    showModal: false,
    showPreviewModal: false,
    languageCode: 'uk',
    contentIndex: 0,
    contentMapCollection: new Map(),
    textFieldProps: {
      // hideDetails: 'auto',
      dense: true,
      outlined: true,
    },
    datePickerProps: {
      'first-day-of-week': 1,
    },
    timePickerProps: {
      format: '24hr',
      useSeconds: true,
    },
    pushVariables: [],
    usersOptions: null,
    booleanOptions: [
      {
        text: 'Так',
        value: true,
      },
      {
        text: 'Ні',
        value: false,
      },
    ],
    valid: true,
    rules: {
      required: (value) => {
        if (Array.isArray(value)) {
          return value.length > 0 || 'Обовʼязкове поле'
        }
        return !!value || value === false || 'Обовʼязкове поле'
      },
    },
  }),

  computed: {
    ...mapState('users', ['users', 'usersMeta']),
    ...mapState('pushNotification', ['currentNotificationSettings']),
    ...mapState('dictionaries', [
      'pushNotificationTypesDictionary',
      'pushNotificationRecipientsDictionary',
      'pushNotificationEventsDictionary',
      'pushNotificationVariablesDictionary',
    ]),
    ...mapGetters('companies', ['getBalanceHolderFilter', 'getServicePointFilter', 'getTerminalFilter', 'getDeviceFilter']),

    currentCompanyId() {
      return +this.$route.params.id
    },
    isSettingsPage() {
      return this.$route.name === 'PushNotificationSettings'
    },
    showPlaceFilter() {
      return this.newNotification.receiver_type === 51 || this.newNotification.receiver_type === 52
    },
    filterDefs() {
      let defs = this.defaultFilterDefs.map((item) => ({ ...item }))
      defs[2].show = false
      defs[3].show = false
      defs[5].show = false
      return defs
    },
    convertedStartAtDate: {
      get: function () {
        return this.newNotification.start_at ? new Date(this.newNotification.start_at) : ''
      },
      set: function (newValue) {
        this.newNotification.start_at = newValue
      },
    },
    convertedExpiredAtDate: {
      get: function () {
        return this.newNotification.expired_at ? new Date(this.newNotification.expired_at) : ''
      },
      set: function (newValue) {
        this.newNotification.expired_at = newValue
      },
    },
  },

  watch: {
    'newNotification.event'(val) {
      if (this.isReceiverTypeAllUsers(val)) {
        this.newNotification.receiver_type = 1
      } else this.newNotification.receiver_type = null
    },
    languageCode: {
      handler(newValue) {
        this.contentIndex = this.newNotification.content.findIndex((item) => item.locale === newValue)
      },
      immediate: false,
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('users', ['loadUsers']),
    ...mapActions('companies', ['loadFilter']),
    ...mapActions('pushNotification', ['createPushNotifications', 'updatePushNotifications']),

    initialize() {
      if (this.isEditable) {
        this.newNotification = CloneDeep(this.currentNotificationSettings)
        this.setPushVariables()
      } else {
        this.newNotification = CloneDeep(this.PUSH_NOTIFICATION_SCHEMA)
        this.setNewNotificationContent()
      }
      this.newNotification.is_one_time = !this.isSettingsPage
      this.newNotification.companies[0] = this.currentCompanyId
      this.usersOptions = {
        loadingFunction: this.loadUsers,
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
        },
      }
      this.loadFilter(this.currentCompanyId)
    },
    setNewNotificationContent() {
      const existingContent = this.contentMapCollection.get(this.languageCode)
      const newContent = {
        locale: this.languageCode,
        title: existingContent ? existingContent.title : '',
        body: existingContent ? existingContent.body : '',
      }
      this.contentMapCollection.set(this.languageCode, newContent)
      this.newNotification.content = Array.from(this.contentMapCollection.values())
    },
    onChangeFormLanguage(lang = 'uk') {
      this.languageCode = lang
      this.setNewNotificationContent()
    },
    onDeleteLanguage(lang) {
      delete this.newNotification.content[lang]
      this.onChangeFormLanguage('uk')
    },
    setPushVariables() {
      const selectedItem = this.pushNotificationVariablesDictionary.find((item) => +item.name === this.newNotification.event)
      if (selectedItem) this.pushVariables = selectedItem.value
    },
    // *** method for v-textarea ***
    // async insertText(text) {
    //   console.log(this.$refs)
    //   // const textarea = this.$refs.textarea.$refs.input
    //   const textarea = this.$refs.textarea.quill
    //   const startPos = textarea.selectionStart
    //   const endPos = textarea.selectionEnd
    //   const currentValue = this.newNotification.content[this.contentIndex].body
    //   this.newNotification.content[this.contentIndex].body =
    //     currentValue.substring(0, startPos) + text + currentValue.substring(endPos, currentValue.length)
    //   // *** Adjust cursor position after inserting text
    //   const newCursorPos = startPos + text.length
    //   await this.$nextTick()
    //   textarea.focus()
    //   textarea.setSelectionRange(newCursorPos, newCursorPos)
    // },
    async insertText(text) {
      const editor = this.$refs.textarea
      if (editor && editor.quill) {
        const quill = editor.quill
        const range = quill.getSelection()
        if (range) {
          quill.insertText(range.index, text)
          quill.setSelection(range.index + text.length)
        } else {
          quill.clipboard.dangerouslyPasteHTML(quill.getLength() - 1, text)
        }
      }
    },
    async openModal() {
      const isValid = await this.$refs.form.validate()
      if (isValid) this.showModal = true
    },
    async openPreviewModal() {
      const isValid = await this.$refs.form.validate()
      if (isValid) this.showPreviewModal = true
    },
    closeModal() {
      this.showModal = false
      this.$refs.form.resetValidation()
    },
    navigateToNotificationsMain() {
      this.$router.push({
        name: 'PushNotificationMain',
      })
    },
    async submit() {
      if (this.showPlaceFilter) {
        this.newNotification.balance_holders = this.selectedBalanceHolders
        this.newNotification.service_points = this.selectedServicePoints
        this.newNotification.devices = this.selectedDevices
      }
      if (this.isEditable) {
        const payload = {
          id: this.currentNotificationId,
          data: this.newNotification,
        }
        await this.updatePushNotifications(payload)
      } else {
        await this.createPushNotifications(this.newNotification)
        this.closeModal()
        this.contentMapCollection.clear()
        if (this.isOneTime) this.initialize()
      }
      if (!this.isOneTime) this.navigateToNotificationsMain()
    },
    isReceiverTypeAllUsers(val) {
      return [1, 2, 3, 4].includes(val)
    },
  },
}
</script>
